import React, { useState, forwardRef, useEffect } from 'react'
import {
    Dialog,
    DialogActions,
    Slide,
    DialogContent,
    DialogTitle,
    Stack,
} from '@mui/material'
import { styled } from '@mui/system'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios.js'
import Palette from 'app/palette'
import Loading from 'app/components/Loading'
import { LoadingContainer } from 'app/utils/customStyle'
import { api_url, api_url_unauth } from 'app/utils/constant'
import { ALL_NAVIGATION } from 'app/components/NavigationDrawer/navigations'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Header = styled(Stack)({
    backgroundColor: Palette.backgroundColor,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
})

const Body = styled(DialogContent)({
    paddingBottom: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: Palette.backgroundColor,
})

const InputField = styled('input')({
    height: '60px',
    width: '60px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 40,
    border: 'none',
    border: `1px solid ${Palette.lightGray}`,
    borderRadius: 2,
    '&:focus': {
        border: `1px solid ${Palette.darkest}`,
    },
    '@media (max-width: 480px)': {
        height: '50px',
        width: '50px',
    },
})


const useStyles = makeStyles({
    title: { color: Palette.darkest },
})

const broadcastChannelTwo = new BroadcastChannel('locker-channel');

const AccessCodeDialog = ({ openDialog = false, updateDialogStatus = () => { } }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)
    const [staffCode1, setStaffCode1] = useState('')
    const [staffCode2, setStaffCode2] = useState('')
    const [staffCode3, setStaffCode3] = useState('')
    const [staffCode4, setStaffCode4] = useState('')
    const [firstCode, setFirstCode] = useState(null)
    const [thirdCode, setThirdCode] = useState(null)
    const [secondCode, setSecondCode] = useState(null)
    const [fourthCode, setFourthCode] = useState(null)
    const [accessCodeErr, setAccessCodeErr] = useState('')
    const [navPermission, setNavPermission] = useState(null)
    const shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))

    useEffect(() => {
        if (staffCode1 && staffCode2 && staffCode3 && staffCode4) {
            setLoading(true)
            handleSubmit()
        }
    }, [staffCode1, staffCode2, staffCode3, staffCode4])

    const handleSubmit = async () => {
        setAccessCodeErr('')

        await axios
            .post(`${api_url}access_code/`,
                {
                    sid: shopInfo.id,
                    access_code: `${staffCode1}${staffCode2}${staffCode3}${staffCode4}`,
                },
                {
                    headers: {
                        Authorization: `token ${loginInfo.accessToken}`,
                    },
                }
            )
            .then((res) => {
                localStorage.setItem('userLockStatus', false)
                setLoading(false)
                if (res.data?.user?.role_id) {
                    check_permission_nav(res)
                } else {
                    localStorage.setItem('loginInfo', JSON.stringify(res.data))
                    localStorage.setItem('activeUser', JSON.stringify(res.data.user))

                    broadcastChannelTwo.postMessage({ name: 'unlock', path: window.location.href })
                }


            })
            .catch((error) => {
                setAccessCodeErr(error.message)
                setLoading(false)
            })
    }




    const getValueByPath = (data, path) => {
        const findValue = (arr, path) => {
            for (const item of arr) {
                if (item.path === path) {
                    return item.value
                }
                if (item.children) {
                    const result = findValue(item.children, path)
                    if (result) {
                        return result
                    }
                }
            }
            return null
        }

        return findValue(data, path)
    }

    const getPathNameByValue = (navigation, value) => {
        for (let i = 0; i < navigation.length; i++) {
            const navItem = navigation[i]

            if (navItem.value === value) {
                return navItem.path
            }

            if (navItem.children) {
                const path = getPathNameByValue(navItem.children, value)
                if (path) {
                    return path
                }
            }
        }
        return null
    }

    const check_permission_nav = (res_data) => {
        let role_id = res_data.data?.user?.role_id
        axios
            .get(`${api_url_unauth}shop/role/${role_id}/`, {
                headers: {
                    Authorization: `token ${loginInfo?.accessToken}`,
                },
            })
            .then((res) => {
                localStorage.setItem('userLockStatus', false)
                let permissions_data = res.data?.permissions || []

                if (permissions_data?.length === 0) {
                    setNavPermission('Something is wrong !')
                    return
                }

                let all_navigation = _.cloneDeep(ALL_NAVIGATION)
                let path_value = getValueByPath(all_navigation, pathname)

                setNavPermission(null)
                localStorage.setItem('loginInfo', JSON.stringify(res_data.data))
                localStorage.setItem(
                    'activeUser',
                    JSON.stringify(res_data.data.user)
                )

                if (permissions_data.includes(path_value)) {
                    broadcastChannelTwo.postMessage({
                        name: 'unlock',
                        path: window.location.href
                    });
                } else {
                    let _pathName = getPathNameByValue(
                        all_navigation,
                        permissions_data[0]
                    )
                    broadcastChannelTwo.postMessage({
                        name: 'unlock',
                        path: _pathName
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog
            open={Boolean(openDialog)}
            onClose={updateDialogStatus}
            TransitionComponent={Transition}
            fullWidth
            scroll="paper"
            maxWidth="xs"
            maxHeight="sm"
        >
            <Header direction={'row'}>
                <DialogTitle className={classes.title}>{t('staff.access_code')}</DialogTitle>
            </Header>
            <Body direction={'row'}>
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-around' }}
                >
                    <InputField
                        autoFocus
                        autoComplete="off"
                        value={staffCode1 && '•'}
                        ref={(input) => {
                            setFirstCode(input)
                        }}
                        type="tel"
                        onKeyUp={(e) => {
                            if (!['Delete', 'Backspace'].includes(e.key)) return
                            firstCode.focus()
                            setStaffCode1('')
                            setStaffCode2('')
                            setStaffCode3('')
                            setStaffCode4('')
                        }}
                        onChange={(event) => {
                            setNavPermission(null)
                            let value = event.target.value
                            if (value.length > 0 && Number(value) || Number(value) == 0) {
                                setStaffCode1(value[value.length - 1])
                                setAccessCodeErr('')
                                setStaffCode2('')
                                secondCode.focus()
                            }
                        }}
                        name="staffCode"
                        pattern="[0-9]*"
                        inputmode="numeric"
                    />
                    <InputField
                        autoComplete="off"
                        value={staffCode2 && '•'}
                        ref={(input) => {
                            setSecondCode(input)
                        }}
                        type="tel"
                        onKeyUp={(e) => {
                            if (!['Delete', 'Backspace'].includes(e.key)) return
                            firstCode.focus()
                            setStaffCode1('')
                            setStaffCode2('')
                            setStaffCode3('')
                            setStaffCode4('')
                        }}
                        onChange={(event) => {
                            setNavPermission(null)
                            let value = event.target.value
                            if (value.length > 0 && Number(value) || Number(value) == 0) {
                                setStaffCode2(value[value.length - 1])
                                setStaffCode3('')
                                setAccessCodeErr('')
                                thirdCode.focus()
                            }
                        }}
                        name="staffCode"
                        pattern="[0-9]*"
                        inputmode="numeric"
                    />
                    <InputField
                        autoComplete="off"
                        value={staffCode3 && '•'}
                        ref={(input) => {
                            setThirdCode(input)
                        }}
                        type="tel"
                        onKeyUp={(e) => {
                            if (!['Delete', 'Backspace'].includes(e.key)) return
                            secondCode.focus()
                            setStaffCode2('')
                            setStaffCode3('')
                            setStaffCode4('')
                        }}
                        onChange={(event) => {
                            setNavPermission(null)
                            let value = event.target.value
                            if (value.length > 0 && Number(value) || Number(value) == 0) {
                                setStaffCode3(value[value.length - 1])
                                setStaffCode4('')
                                setAccessCodeErr('')
                                fourthCode.focus()
                            }
                        }}
                        name="staffCode"
                        pattern="[0-9]*"
                        inputmode="numeric"
                    />
                    <InputField
                        autoComplete="off"
                        value={staffCode4 && '•'}
                        ref={(input) => {
                            setFourthCode(input)
                        }}
                        type="tel"
                        onKeyUp={(e) => {
                            if (!['Delete', 'Backspace'].includes(e.key)) return
                            thirdCode.focus()
                            setStaffCode3('')
                            setStaffCode4('')
                        }}
                        onChange={(event) => {
                            setNavPermission(null)
                            let value = event.target.value
                            if (value.length > 0 && Number(value) || Number(value) == 0) {
                                setStaffCode4(value[value.length - 1])
                            }
                        }}
                        name="staffCode"
                        pattern="[0-9]*"
                        inputmode="numeric"
                    />
                </Stack>
                {accessCodeErr?.length > 0 && (
                    <div
                        align="center"
                        style={{
                            color: 'red',
                            marginTop: 10,
                        }}
                    >
                        {accessCodeErr}
                    </div>
                )}

                {navPermission && (
                    <div
                        align="center"
                        style={{
                            color: 'red',
                            marginTop: 10,
                        }}
                    >
                        {navPermission}
                    </div>
                )}
            </Body>
            {loading && (
                <LoadingContainer container fullHeight>
                    <Loading sx={{ position: 'fixed' }} size={24} />
                </LoadingContainer>
            )}
        </Dialog>
    )
}

export default AccessCodeDialog
