import React, { createContext, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import AccessCode from 'app/components/Dialog/Others/AccessCode'
import { MatxLayoutSettings } from 'app/components/Theme/Layout/settings'


const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => { },
})

export const SettingsProvider = ({ settings, children }) => {
    const userLockStatus = useSelector(state => state.globalData?.userLockStatus);
    const userLockStatusLocal = JSON.parse(localStorage.getItem('userLockStatus'))
    const [currentSettings, setCurrentSettings] = useState(settings || MatxLayoutSettings)

    const handleUpdateSettings = (update = {}) => {
        const marged = _.merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <>
            <AccessCode openDialog={userLockStatus || userLockStatusLocal} updateDialogStatus={() => { }} />
            <SettingsContext.Provider value={{ settings: currentSettings, updateSettings: handleUpdateSettings, }}>
                {children}
            </SettingsContext.Provider>
        </>
    )
}

export default SettingsContext
