import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEN from 'app/utils/Translation/en/translationEN.json'
import translationCN from 'app/utils/Translation/cn/translationCN.json'
import translationBM from 'app/utils/Translation/bm/translationBM.json'
import translationCN_T from 'app/utils/Translation/cn/translationCN_T.json'
import translationCN_C from 'app/utils/Translation/cn/translationCN_C.json'

const resources = {
    en: {
        translation: translationEN,
    },
    'cn': {
        translation: translationCN,
    },
    'cn_t': {
        translation: translationCN_T,
    },
    'cn_c': {
        translation: translationCN_C,
    },
    bm: {
        translation: translationBM,
    },
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        returnEmptyString: false,
        resources,

        // have a common namespace used around the full app
        // ns: ["translations"],
        // defaultNS: "translations",

        // keySeparator: false, // we use content as keys

        // interpolation: {
        //   escapeValue: false, // not needed for react as it escapes by default
        // }
    })

export default i18n
