import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import RootReducer from './reducers/RootReducer'
import { thunk } from 'redux-thunk'

const middleware = [thunk]
const devtools = (x) => x

export const Store = configureStore({
  reducer: RootReducer,
  middleware: [...getDefaultMiddleware(), ...middleware],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [devtools],
})

export default Store
