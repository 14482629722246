import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import './app.css'
import { Store } from './redux/Store'
import Theme from './components/Theme/theme'
import { register } from './serviceWorkerRegistration'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import NotificationBar from 'app/components/NotificationBar'
import NetworkIndicator from './components/NetworkIndicator'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { AllPages, ReceiptPage, AppointmentPage } from './routes/routes'


const App = () => {
    const [title, setTitle] = useState('LaBéau Dashboard')
    const [registration, setRegistration] = useState(null)
    const [updateAvailable, setUpdateAvailable] = useState(false)

    const AppRoutes = () => {
        const host = window.location.hostname
        let route = []
        if (host.startsWith('appointment')) {
            console.log("HERE: ")
            route = AppointmentPage()
        } else if (host.startsWith('receipt') || host.startsWith('release-receipt')) {
            route = ReceiptPage()
        } else {
            route = AllPages()
        }
        return useRoutes(route)
    }

    useEffect(() => {
        const host = window.location.hostname
        if (host.startsWith('appointment') || host.startsWith('release-appointment')) {
            setTitle('Appointment')
        } else if (host.startsWith('receipt') || host.startsWith('release-receipt')) {
            setTitle('Customer Receipt')
        } else {
            setTitle('LaBéau Dashboard')
        }
    }, [window.location.hostname])

    useEffect(() => {
        register({
            onUpdate: (registration) => {
                console.log('A new version is available. Ready to update!')
                // Handle app update logic here (e.g., show a Snackbar)
                setUpdateAvailable(true)
                setRegistration(registration)
            },
        })
    }, [])


    const handleCloseUpdateSnackbar = () => {
        // setUpdateAvailable(false)
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })

            // Hide the Access Code Dialog if it is showed
            localStorage.setItem('userLockStatus', false)
            // Clear off the logged in user info
            localStorage.removeItem('activeUser')
            localStorage.removeItem('loginInfo')
            // Redirect the user to the login page
            window.location.replace('/dashboard_login')
        }
    }

    return (
        <Provider store={Store}>
            <SettingsProvider>
                <Theme>
                    <NetworkIndicator />
                    <AuthProvider>
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                        {AppRoutes()}
                        <NotificationBar handleUpdate={handleCloseUpdateSnackbar} open={updateAvailable} />
                        {/* <HomeScreenShortcut /> */}
                    </AuthProvider>
                </Theme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
