import React, { lazy } from 'react'
import StaffRoleContext from './StaffRoleContext'
import Loadable from 'app/components/Loadable/Loadable'

const StaffList = Loadable(lazy(() => import('./StaffList')))
const StaffRole = Loadable(lazy(() => import('./StaffRole')))
const TimecardList = Loadable(lazy(() => import('./TimeardList')))
const DailyTimecard = Loadable(lazy(() => import('./DailyTimecard')))
const MonthlyTimecard = Loadable(lazy(() => import('./MonthlyTimecard')))

const staffRoutes = [
    {
        path: '/staff/daily_timecard',
        element: <DailyTimecard />,
    },
    {
        path: '/staff/monthly_timecard',
        element: <MonthlyTimecard />,
    },
    {
        path: '/staff/list',
        element: <StaffList />,
    },
    {
        path: '/staff/role',
        element: (
            <StaffRoleContext>
                <StaffRole />
            </StaffRoleContext>
        ),
    },
    {
        path: '/staff/timecard',
        element: <TimecardList />,
    },
]

export default staffRoutes
